import { useRef } from 'react';
import { Link, makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  ListWrapper,
  Table,
  Filters,
  Autocomplete,
  DatePicker,
  Button,
  MultiSelect,
} from 'Components';

import { useList } from 'Hooks';

import { toMoneyFormat, AJAX } from 'Helpers';

import { Order, OrdersInfoProps, Payment } from './types';

const useStyles = makeStyles((theme) => ({
  canceled: {
    backgroundColor: '#fde5e561',
  },
  button: { marginLeft: theme.spacing(3) },
}));

const DEFAULT_SORT_PARAMS = {
  sort_column: 'created_at',
  sort_direction: 'desc',
};

export const OrdersInfo = ({
  filter,
  export_url,
  export_api_url,
  fetch_url,
  default_filters,
  role_company_staff,
}: OrdersInfoProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const rowClassFn = (item: Order) => {
    return item.canceled_at ? classes.canceled : null;
  }

  const tableConfig = [
    {
      title: t('Order ID'),
      accessor: 'order_id',
    },
    {
      title: t('User'),
      accessor: 'user',
    },
    {
      title: t('Client'),
      cellComponent: (item: Order) => {
        if (!!item.client) {
          return (
            <Link href={item.client_url}>{item.client.full_name || ''}</Link>
          );
        }
        return null;
      },
      accessor: 'client',
    },
    {
      title: t('Certificate Code'),
      accessor: 'certificate_code',
    },
    {
      title: t('Final Price'),
      cellComponent: ({ final_price }: Order) => toMoneyFormat(final_price),
      accessor: 'final_price',
    },
    {
      title: t('Price per payment type'),
      cellComponent: ({ order_payment_details }: Order) =>
        order_payment_details.map((order_payment_detail) => (
          <p>{`${order_payment_detail.payment_type}: ${toMoneyFormat(
            order_payment_detail.amount
          )}`}</p>
        )),
    },
    {
      title: t('Created At'),
      accessor: 'created_at',
      sortable: true,
    },
    {
      title: t('Cancel'),
      accessor: 'canceled_at',
    },
    {
      title: t('Send back to'),
      accessor: 'send_back_to',
      cellComponent: ({ send_back_to }: Order) => {
        if (!!send_back_to) {
          return t(send_back_to);
        }
      },
    },
  ];

  const collapseTableConfid = [
    {
      title: t('Service'),
      accessor: 'service',
    },
    {
      title: t('Purchased Service Id'),
      accessor: 'purchased_service_id',
    },
    {
      title: t('Price'),
      cellComponent: ({ price }: Payment) => toMoneyFormat(price),
      accessor: 'price',
    },
    {
      title: t('Discount'),
      cellComponent: ({ discount }: Payment) => `${discount}%`,
      accessor: 'discount',
    },
  ];

  const {
    pending,
    data,
    records_total,
    paginationParams,
    handleChangePagination,
    setFilters,
  } = useList({
    fetchUrl: fetch_url,
    sorting: DEFAULT_SORT_PARAMS,
    initialFilters: default_filters,
    onSuccessFetch: undefined,
    additionalFetchParams: {},
  });

  const onSubmitFilters = ({
    from,
    till,
    locations,
    ...filters
  }: {
    from: string;
    till: string;
    locations: string[];
  }) => {
    setFilters({
      ...filters,
      ...(!!default_filters.users && { users: default_filters.users }),
      ...(!!locations.length && { locations }),
      ...((!!from || !!till) && {
        created_at: {
          ...(!!from && { from }),
          ...(!!till && { till }),
        },
      }),
    });
  }

  const filterRef = useRef(null!);

  const handleExportCSV = () => {
    const { locations, from, till, ...other } = filterRef.current.values;
    const filterToFetch = {
      ...other,
      ...(!!locations.length && { locations: locations }),
      ...((!!from || !!till) && {
        created_at: {
          ...(!!from && { from }),
          ...(!!till && { till }),
        },
      }),
    };
    AJAX.post(export_api_url, { body: { filters: filterToFetch } });
  }

  return (
    <ListWrapper
      title={`${t('Orders Info')} (${records_total})`}
      headerAddon={
        <Box>
          <Button
            color="primary"
            onClick={handleExportCSV}
            className={classes.button}
            disabled={true}
          >
            {t('Export CSV')}
          </Button>
          <Button
            color="primary"
            href={export_url}
            className={classes.button}
            disabled={true}
          >
            {t('CSV Files')}
          </Button>
        </Box>
      }
    >
      <Filters
        ref={filterRef}
        onSubmit={onSubmitFilters}
        initialValues={{
          locations: [],
          from: default_filters.created_at?.from || '',
          till: default_filters.created_at?.till || '',
          order_type: default_filters.order_type || '',
        }}
      >
        <DatePicker
          label={t('From')}
          name="from"
          disabled={!!default_filters?.created_at?.from}
        />
        <DatePicker
          label={t('Till')}
          name="till"
          disabled={!!default_filters?.created_at?.till}
        />
        <Autocomplete
          label={t('Order type')}
          options={filter.order_types}
          name="order_type"
        />
        <Autocomplete
          label={t('Payment type')}
          options={filter.payment_types}
          name="payment_type"
          valueAsArray
        />
        {!role_company_staff && (
          <Autocomplete
            label={t('User')}
            options={filter.users}
            name="users"
            valueAsArray
          />
        )}
        <MultiSelect
          options={filter.locations}
          label={t('Locations')}
          name="locations"
          required={false}
        />
      </Filters>
      <Table
        config={tableConfig}
        pending={pending}
        data={data}
        pagination={{ records_total, paginationParams, handleChangePagination }}
        rowClassFn={rowClassFn}
        collapseRow={(item: Order) => (
          <Table
            config={collapseTableConfid}
            data={item.payments}
            showPagination={false}
          />
        )}
      />
    </ListWrapper>
  );
};
