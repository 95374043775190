import { makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from 'Components';

const useStyles = makeStyles(() => ({
  assignBtn: {
    marginRight: '8px',
  },
}));

const SUCCESS_PAYMENT_STATUS = 'success';

export const ActionsCell = ({ item, onClick, onDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isPaymentStatusSuccess = item.payment_status === SUCCESS_PAYMENT_STATUS;

  return (
    <>
      <Button
        size="small"
        color="primary"
        onClick={onClick}
        className={classes.assignBtn}
        disabled={!item.client_id}
      >
        {t('Assign Services')}
      </Button>
      <Tooltip
        title={t('Delete')}
        arrow
      >
        <IconButton
          size="small"
          onClick={() => onDelete(item.id)}
        >
          <i className="icon-trash-empty"></i>
        </IconButton>
      </Tooltip>
      {isPaymentStatusSuccess && (
        <Tooltip
          title={t('Delete and refund to deposit')}
          arrow
        >
          <IconButton
            size="small"
            onClick={() => onDelete(item.id, true)}
          >
            <i className="icon-ccw"></i>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
