import MAutocomplete from '@material-ui/lab/Autocomplete';
import { FormHelperText } from '@material-ui/core';
import { useTranslation, } from 'react-i18next';
import { FormInput } from 'Components';
import { setObjectValue, AJAX, } from 'Helpers';

let request, timeout = null;
export const Autocomplete = ({
  label, name, value, options, getOptionLabel, onChange, searchUrl, disabled,
  getRequestBody, method = 'post', apiName, fetchOnInit = false, getOptionSelected, required,
  validationMessages }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    inputValue: value || '',
    options,
    loading: false,
  });

  function handleChange(e, value) {
    setState({ ...state, inputValue: value ? getOptionLabel(value) : '' });

    onChange(setObjectValue(e, 'target.value', value ? value.id : ''), value);
  }

  function handleSearch(value) {
    setState((prevState) => { return { ...prevState, loading: true } });

    if (!!request) {
      request.abort();
    }
    request = AJAX[method](searchUrl, {
      body: {
        ...(!!getRequestBody ? getRequestBody(value) : { value })
      }
    });
    request.then(res => {
      setState({
        ...state,
        loading: false,
        options: res[apiName || name],
        inputValue: value,
      })
      request = null;
      timeout = null;
    })
  }

  React.useEffect(() => {
    if (!!fetchOnInit) {
      handleSearch('')
    }
  }, [])

  function handleInputChange(e) {
    if (e?.target?.tagName !== 'INPUT') {
      return;
    }

    e.persist();

    setState({
      ...state,
      inputValue: e.target.value,
    });
    if (!searchUrl) {
      return
    }

    if (!!timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      handleSearch(e.target.value);
    }, 500);
  }

  return (
    <>
      <MAutocomplete
      disabled={disabled}
        autoHighlight
        fullWidth
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        loadingText={t('Loading...')}
        noOptionsText={t('Nothing Found')}
        onInputChange={handleInputChange}
        forcePopupIcon={false}
        {...getOptionSelected && {
          getOptionSelected
        }}
        {...state}
        renderInput={(params) => (
          <FormInput
            {...params}
            label={label}
            name={name}
            required={required}
            error={!!validationMessages}
            autoComplete='new-password'
            {...params.inputProps}
          />
        )}
      />
      <FormHelperText error={!!validationMessages}>{validationMessages}</FormHelperText>
    </>
  );
}

Autocomplete.defaultProps = {
  getOptionLabel: ({ name }) => name,
  options: [],
}
