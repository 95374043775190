export const BookingEvent = {
  en: {
    'Event': 'Event',
    'Location': 'Location',
    'Provider': 'Provider',
    'Booking Service': 'Booking Service',
    'Time': 'Time',
    'Members': 'Members',
    'Search': 'Search',
    'Client': 'Client',
    'Add member': 'Add member',
    'Add': 'Add',
    'Comment': 'Comment',
    'Notes': 'Notes',
    'Comment for provider': 'Comment for provider',
    'Last Updated by': 'Last Updated by',
    'Assign services to client': 'Assign services to client',
    'Use after assign': 'Use after assign',
    'Assign': 'Assign',
    'Assign Services': 'Assign Services',
    'Members limit': 'Members limit',
    'Assigned Services': 'Assigned Services',
    'New client': 'New client',
    'out of': 'out of',
    'Created': 'Created',
    'Updated': 'Updated',
    'Phone': 'Phone',
    'Wait accept': 'Wait accept',
    'Success': 'Success',
    'Payment status': 'Payment',
    'Added by user': 'Added by user',
    'In process': 'In process',
    Delete: 'Delete',
    'Delete and refund to deposit': 'Delete and refund to deposit',
    DeleteMemberToDepositConfirmText: 'Are you sure you want to delete member and return the money to the deposit?',
    DeleteMember: 'Are you sure you want to delete member?'
  },
  ru: {
    'Event': 'Событие',
    'Location': 'Локация',
    'Provider': 'Провайдер',
    'Booking Service': 'Букинг Сервис',
    'Time': 'Время',
    'Members': 'Участники',
    'Search': 'Поиск',
    'Client': 'Клиент',
    'Add member': 'Добавить участника',
    'Add': 'Добавить',
    'Comment': 'Комментарий',
    'Last Updated by': 'Обновил',
    'Assign services to client': 'Присвоение сервисов клиенту',
    'Use after assign': 'Использовать после присвоения',
    'Assign': 'Присвоить',
    'Assign Services': 'Присвоить сервисы',
    'Members limit': 'Ограничение кол-ва участников',
    'Assigned Services': 'Присвоенные сервисы',
    'New client': 'Новый Клиент',
    'out of': 'из',
    'Created': 'Создал',
    'Updated': 'Обновил',
  },
  uk: {
    'Event': 'Подія',
    'Location': 'Локація',
    'Provider': 'Провайдер',
    'Booking Service': 'Букінг Сервіс',
    'Time': 'Час',
    'Members': 'Учасники',
    'Search': 'Пошук',
    'Client': 'Клієнт',
    'Add member': 'Додати учасника',
    'Add': 'Додати',
    'Comment': 'Коментар',
    'Notes': 'Нотатки',
    'Comment for provider': 'Коментар для провайдера',
    'Last Updated by': 'Оновив',
    'Assign services to client': 'Присвоєння сервісів клієнту',
    'Assign Services': 'Присвоєння сервісів',
    'Use after assign': 'Використати після присвоєння',
    'Assign': 'Присвоїти',
    'Members limit': 'Обмеження кількості учасників',
    'Assigned Services': 'Присвоєні сервіси',
    'New client': 'Новий Клієнт',
    'out of': 'із',
    'Created': 'Створив',
    'Updated': 'Оновив',
    'Phone': 'Телефон',
    'Wait accept': 'В обробці',
    'Success': 'Успішна',
    'Payment status': 'Оплата',
    'Added by user': 'Доданий адміном',
    'In process': 'Очікується',
    Delete: 'Видалити',
    'Delete and refund to deposit': 'Видалити і повернути на депозит',
    DeleteMemberToDepositConfirmText: 'Ви впевнені, що хочете видалити учасника та повернути кошти на депозит?',
    DeleteMember: 'Ви впевнені, що хочете видалити учасника?'
  },
};
