import { AJAX } from 'Helpers';
import { DEFAULT_PAGINATION_PARAMS } from 'Constants/pagination';
import notificationsStore from 'Helpers/notifications';

/**
 * usePagination returns pagination params and change value params function
 * @param {object} pagination object of default pagination params.
 * it must have "page" and "size_per_page" keys
 */
export function usePagination(pagination, sorting) {
  if (!pagination && !sorting) {
    return {};
  }

  const [paginationParams, changePaginationParams] = React.useState({
    ...pagination,
    ...sorting,
  });

  function handleChangePagination(pagination) {
    changePaginationParams({ ...paginationParams, ...pagination });
  }

  return { paginationParams, handleChangePagination };
}

export function useListFetch({
  fetchUrl,
  fetchMethod,
  paginationParams,
  filters,
  search,
  onSuccessFetch,
  additionalParams = {},
}) {
  const [state, changeState] = React.useState({
    pending: false,
    data: [],
    records_filtered: 0,
    records_total: 0,
  });

  React.useEffect(
    function () {
      updateList();
    },
    [paginationParams, filters, search]
  );

  function defaultOnSuccessPage(newData) {
    changeState({ ...state, ...newData, pending: false });
  }

  function updateList() {
    fetchData({
      body: {
        ...(paginationParams || {}),
        ...additionalParams,
        ...(filters && { filters }),
        ...(!!search && {
          search: {
            value: search,
          },
        }),
      },
    });
  }

  function fetchData(params) {
    changeState({ ...state, pending: true });
    const request = AJAX[fetchMethod](fetchUrl, params);
    request.then(function (res) {
      if (res.status === 'error') {
        notificationsStore.add({ type: 'error', text: res.errors });
      }
      if (onSuccessFetch === undefined) {
        defaultOnSuccessPage(res);
      } else if (!!onSuccessFetch) {
        onSuccessFetch(res);
      }
    });

    return request;
  }

  return { ...state, fetchData, updateList };
}

export function useFilters(initialFilters = {}) {
  const [filters, setFilters] = React.useState(initialFilters);

  function changeFilter(filter) {
    setFilters({ ...filters, ...filter });
  }

  return {
    filters,
    setFilters,
    changeFilter,
  };
}

export function useSearch() {
  const [search, setSearch] = React.useState('');

  function changeSearch({ value }) {
    setSearch(value);
  }

  return {
    search,
    setSearch,
    changeSearch,
  };
}

export function useList({
  fetchUrl,
  fetchMethod = 'get',
  onSuccessFetch,
  additionalFetchParams,
  pagination = DEFAULT_PAGINATION_PARAMS,
  initialFilters,
  sorting = {},
}) {
  const paginationData = usePagination(pagination, sorting);
  const { paginationParams } = paginationData;
  const { filters, ...filterFunctions } = useFilters(initialFilters);
  const { search, ...searchFunctions } = useSearch();
  const listData = useListFetch({
    fetchUrl,
    fetchMethod,
    paginationParams,
    filters,
    search,
    onSuccessFetch,
    additionalParams: additionalFetchParams,
  });

  return {
    ...listData,
    ...paginationData,
    ...filterFunctions,
    ...searchFunctions,
    filters,
  };
}
