import { useRef } from 'react';
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Container,
} from '@material-ui/core';

import { ListWrapper, Preloader } from 'Components';
import { getCorrectDays } from 'Helpers';

import UnsubscribeConfirm from '../subscriptions/Confirm';
import { OnlineService } from '../booking/services_list/OnlineService';
import { ActiveSubscription } from './active_subscription';
import { useMySubscriptionInformation } from 'queries/useMySubscriptionInformation';

import { ActiveSubscriptionType } from 'schemas';
import { MySubscriptionsProps } from './types';
import { SubscriptionForResubscribe } from './subscription_for_resubscribe';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  content: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listWrapper: {
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  unSubscribeButton: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px auto`,
      display: 'block',
    },
  },
  preloader: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    color: theme.palette.primary.main,
  },
  listTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '24px',
  },
  list: {
    background: '#e4f9f8',
    boxShadow: 'none',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: theme.spacing(2),
  },
  cards: {
    width: '75%',
    margin: `${theme.spacing(1)}px auto`,
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(3)}px 0 `,
      width: '100%',
    },
  },
}));

export const MySubscription = ({
  my_subscription_url,
  online_services_url,
  services_url,
}: MySubscriptionsProps) => {
  const classes = useStyles();

  const unsubscribeConfirm = useRef<{
    open: (subscription: ActiveSubscriptionType) => void;
  }>(null!);

  const unsubscribe = (subscription: ActiveSubscriptionType) => () => {
    unsubscribeConfirm.current.open(subscription);
  };

  const { data: mySubscriptionInformation, isLoading } =
    useMySubscriptionInformation({
      fetchUrl: my_subscription_url,
    });

  const isActiveSubscriptionsPresent =
    !!mySubscriptionInformation?.data.active_subscriptions.length;

  const isResubscribeAvailable = !!mySubscriptionInformation?.data.subscriptions_for_resubscribe;

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.listWrapper}>
          {isLoading ? (
            <ListWrapper
              title="Завантаження..."
              className={classes.list}
              titleClassName={classes.listTitle}
            >
              <Box className={classes.preloader}>
                <Preloader />
              </Box>
            </ListWrapper>
          ) : (
            <>
              {!isActiveSubscriptionsPresent && !isResubscribeAvailable && (
                <ListWrapper
                  title="Підписки відсутні"
                  className={classes.list}
                  titleClassName={classes.listTitle}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      component="h5"
                    >
                      Перейдіть до сторінки{' '}
                      <a href={online_services_url}>
                        Клубні картки та абонементи
                      </a>{' '}
                      для придбання підписки.
                    </Typography>
                  </Box>
                </ListWrapper>
              )}
              {mySubscriptionInformation?.data.subscriptions_for_resubscribe && (
                <SubscriptionForResubscribe
                  subscription={mySubscriptionInformation.data.subscriptions_for_resubscribe}
                  services_url={services_url}
                />
              )}
              {isActiveSubscriptionsPresent &&
                mySubscriptionInformation.data.active_subscriptions.map(
                  (subscription) => (
                    <ActiveSubscription
                      key={subscription.id}
                      subscription={subscription}
                      unsubscribe={unsubscribe}
                    />
                  )
                )}
            </>
          )}
        </Box>
      </Box>
      {isActiveSubscriptionsPresent && (
        <UnsubscribeConfirm ref={unsubscribeConfirm} />
      )}
    </Box>
  );
};
